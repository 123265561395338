import { decrypt } from "@/helpers/utils/encrypt";



const allPermission = localStorage.getItem('permissions') ? JSON.parse(decrypt(localStorage.getItem('permissions'))) : '';

export function hasPermission(permissionName) {
  // console.log(store.getters['user/getPermissions'])
  if (allPermission && allPermission.find(x => x === permissionName)) {
    // console.log('true'+ permissionName)
    return true
  } else {
    // console.log('false'+ permissionName)
    return false
  }
}