<template>
  <div class="home">
    <div class="notification">
      <h4 class="title">Bildirimler</h4>

      <div class="action">
        <p @click="allRead">Tümünü okundu olarak işaretle</p>
        <div class="d-flex">
          <p>Yalnzıca okunmayanları göster</p>
          <el-switch class="switch" v-model="onlyUnReadShow" :active-value="false" :inactive-value="true"
            active-color="#15284b" inactive-color="#00547B" @change="switched">
          </el-switch>
        </div>
      </div>
      <div class="notification-item" v-for="item in getNotifications" :class="!item.isRead ? 'first' : ''">
        <div class="d-flex">
          <!-- <div class="notification-img">
                <img
                  src="https://media.licdn.com/dms/image/v2/C4D03AQHozGFabhf0vA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1659819418438?e=1732147200&v=beta&t=E5W6hO5l8rUq2b2g7WkckRXtqNOop96yuPLvQPMwqzU">
              </div> -->
          <div class="notification-detail">
            <div class="d-flex between">
              <p class="notification-user">{{ item.title }}</p>
              <p class="notification-date">{{ new Date(item.createdDate).toLocaleDateString("tr-TR") }}</p>
            </div>

            <p class="notification-title">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


import { mapGetters, mapActions } from 'vuex'
export default {

  name: "Notification",
  data() {
    return {
      onlyUnReadShow: true,
    }
  },

  computed: {
    ...mapGetters({
      getUser: "user/getUser",
      getAppInfo: 'app/getAppInformation',
      getNotifications: 'app/getNotifications',
      getFilteredNotificationList: 'app/getFilteredNotificationList',
    }),


  },

  methods: {
    switched() {
      let isNotReadedNotifications = this.getFilteredNotificationList.contents.filter(item => item.isRead == this.onlyUnReadShow)
      this.$store.commit('app/setFilteredNotificationList', isNotReadedNotifications)
    },

    async allRead() {
      let notificationsIds = []
      this.getNotifications.forEach(element => {
        if (!element.isRead)
          notificationsIds.push(element.id)
      });

      await this.$axios.put("/api/notifications/all/read", {
        ids: notificationsIds
      })
      this.$store.dispatch('app/getNotification')
    },
  },

  created() {
    this.$store.dispatch('app/getNotification')
  },

}
</script>
<style lang="scss" scoped>
.notification {
  width: 60%;
  padding: 0 50px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
  }

  &-item {
    background-color: white;
    padding: 25px 25px 20px 25px;
    border-radius: 12px;

    border: 1px solid #f2f2f2;
    margin-bottom: 10px;

    &.first {
      background-color: #15284b0d;
    }
  }


  .switch {
    margin-left: 4px;
  }

  .d-flex {
    display: flex;
  }

  .action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 15px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 8px;

    p {
      cursor: pointer;
    }
  }

  .between {
    justify-content: space-between;
  }

  .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  &-date {
    font-size: 12px;
  }

  &-user {
    font-size: 15px;
    font-weight: 600;
    color: #15284b;
  }

  &-title {
    font-size: 14px;
    margin-top: 8px;
  }

  &-detail {
    width: 100%;
    margin-top: 3px;
  }

  &-img {
    margin-right: 10px;

    img {
      width: 50px;
      border-radius: 50px;
      border: 1px solid #15284b;
    }
  }
}
</style>