<template>
  <div class="home">
    <div class="home-row">
      <today-statistics
        v-if="$hasPermission('Dashboard.TotalQualityControlAndCollectedProductWidget')"></today-statistics>
    </div>
    <div class="home-row">
      <last-24-hours-order-chart v-if="$hasPermission('Dashboard.OrderCountChart') && !isMobile" />
      <total-order-chart v-if="$hasPermission('Dashboard.OrderCountChart') && !isMobile" />
      <order-sources-chart v-if="$hasPermission('Dashboard.OrderSourceChart')" />
      <warehouse-transfer-chart v-if="$hasPermission('Dashboard.WarehouseProductTransfer')" />

      <div class="home-column" style="max-width: min-content;"
        v-if="$hasPermission('Dashboard.PersonalStatistics') || $hasPermission('Dashboard.OrderOperationSpeedWidget')">
        <operation-speed v-if="$hasPermission('Dashboard.OrderOperationSpeedWidget')"></operation-speed>
        <personal-statistics v-if="$hasPermission('Dashboard.PersonalStatistics')"></personal-statistics>
      </div>
    </div>

  </div>
</template>

<script>

import TodayStatistics from './TodayStatistics.vue'
import Last24HoursOrderChart from './Last24HoursOrderChart.vue';
import TotalOrderChart from './TotalOrderChart.vue';
import OrderSourcesChart from './OrderSourcesChart.vue';
import WarehouseTransferChart from './WarehouseTransferChart.vue';
import OperationSpeed from './OperationSpeed.vue'
import PersonalStatistics from './PersonalStatistics.vue';

import { mapGetters } from 'vuex'
export default {
  components: {
    PersonalStatistics,
    TodayStatistics,
    Last24HoursOrderChart,
    TotalOrderChart,
    OrderSourcesChart,
    OperationSpeed,
    WarehouseTransferChart
  },
  name: "Home",

  computed: {
    ...mapGetters({
      getUser: "user/getUser",
    }),
  },

  beforeMount() {
    this.$store.commit("app/changeAxiosLoadingVisibilty", false) //loading kapatmak için
  },
  mounted() {
    document.body.classList.add('home-page')
    //ilk istek hata gelirse direkt panelden atsın diye ayrı alana yazıldı
    try {
      if (this.getUser.storeID !== 0) {
        if (this.$hasPermission('Dashboard.OrderCountChart')) this.$store.dispatch('adminDashboard/getLast24hOrders')
        if (this.$hasPermission('Dashboard.OrderCountChart')) this.$store.dispatch('adminDashboard/getAllOrders')
      }

    } catch (error) {
      console.log(error)
      return
    }
    try {
      if (this.getUser.storeID !== 0) {
        if (this.$hasPermission('Dashboard.OrderSourceChart')) this.$store.dispatch('adminDashboard/getOrderSources')
        if (this.$hasPermission('Dashboard.TotalQualityControlAndCollectedProductWidget')) this.$store.dispatch('adminDashboard/getTodayGeneralStatistic')
        if (this.$hasPermission('Dashboard.UsedCarsChart')) this.$store.dispatch('adminDashboard/getCarUsingCounts')
        if (this.$hasPermission('Dashboard.OrderOperationSpeedWidget')) this.$store.dispatch('adminDashboard/getOperationSpeed')
        if (this.$hasPermission('Dashboard.WarehouseProductTransfer')) this.$store.dispatch('adminDashboard/getWarehouseTransferData')
      }
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    this.$store.commit("app/changeAxiosLoadingVisibilty", true)
    document.body.classList.remove('home-page')
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/pages/home";
</style>