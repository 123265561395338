import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/locales'
import Home from '@/views/Home'
import AdminDashboard from '@/views/AdminDashboard/AdminDashboard'
import Notifications from '@/views/AdminDashboard/Notification'
import PageNotFound from '@/views/Error/404NotFound'
import Unauthorized from '@/views/Error/401Unauthorized'
import store from '@/store'

Vue.use(VueRouter)

import settings from '@/router/settings'
import orderManagement from '@/router/order-management'
import warehouseManagement from '@/router/warehouse-management'
import refundManagement from '@/router/refund-management'
import productManagement from '@/router/product-management'
import reports from '@/router/reports'
import setup from '@/router/setup'
import courier from '@/router/courier'

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home,
    meta: {
      title: 'Anasayfa',
      layout: 'AppLayoutNoBg',
    },
  },
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      title: 'Admin Dashboard',
      layout: 'AppLayoutNoBg',
    },
  },
  {
    path: '/bildirimler',
    name: 'Bildirimler',
    component: Notifications,
    meta: {
      title: 'Bildirimler',
      layout: 'AppLayoutNoBg',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login/Login.vue'),
    meta: {
      title: 'GirisYap',
      layout: 'LoginLayout',
    },
    beforeEnter: (to, from, next) => {
      if (Vue.$cookies.get('userToken')) {
        return document.location = '/'
      }
      next()
    }
  },
  {
    path: '/:host/forgot-password/:guid/:hash',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/Login/ForgotPassword.vue'),
    meta: {
      title: 'ŞifremiUnuttum',
      layout: 'LoginLayout',
    },
  },
  ...settings,
  ...orderManagement,
  ...warehouseManagement,
  ...refundManagement,
  ...productManagement,
  ...reports,
  ...setup,
  ...courier,
  { path: "/401", name:'401Page', component: Unauthorized },
  { path: "*", name:'404Page', component: PageNotFound }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.params.title) {
    document.title = `${to.params.title} ${i18n.t(to.meta.title)} | Ticimax WMS`
  } else {
    document.title = `${i18n.t(to.meta.title)} | Ticimax WMS`
  }
  const userToken = Vue.$cookies.get('userToken')
  if (!userToken && !window.location.toString().includes("login") && !window.location.toString().includes("forgot-password")) {
    // console.log('token yok')
    //TODO login sayfası yazıldığında bu kısımlar düzenlenip taşınacak.
    // this.$router.replace(sessionStorage.getItem('redirectPath') || '/');
    // sessionStorage.removeItem('redirectPath');
    // sessionStorage.setItem('redirectPath', to.path);
    // next('/login');
   
    document.location = '/login'
  } else {
    if (to.meta.auth) {
      if (Vue.prototype.$hasPermission(to.meta.auth)) {
        next();
      } else {
        //yetki yok geri gönder
        next({name: '401Page'})
      }
    } else {
      //kontrol olmadığı için devam eder
      next();
    }
  }
});

export default router
