import Vue from "vue";

const state = () => ({
  app : {
    apiVersion: '',
    language: 'tr',
    isMobile: false,
  },
  axios: {
    showLoading: true,
  },
  userMenuDrawer: false,
  serverStatus: true,
  barcode: "",
  popups: {
    findProduct: false, //ürün ara popup 
    findOrder: false, //sipariş ara popup
  },
  //ülke-şehir-semt listeleri
  countryList: [],
  cityList: [],
  districtList: [],
  //mağaza ve depo listeleri
  storeList: [],
  warehouseList: [],
  warehouseListWithStore: [],
  //kullanıcı listeleri
  userList: [],
  notificationList:[],
  filteredNotificationList:[]
})
  
// getters
const getters = {
  getAppInformation(state) {
    return state.app;
  },
  getServerStatus(state) {
    return state.serverStatus;
  },
  getBarcode(state) {
    return state.barcode;
  },
  getNotifications(state) {
    return state.notificationList;
  },
  getFilteredNotificationList(state) {
    return state.filteredNotificationList;
  },
};

// actions
const actions = {
  setAppInformationAction({commit, state}, mobileStatus) {  
    commit('setApiVersion', Vue.$cookies.get('apiVersion'))
    commit('setMobileStatus', mobileStatus)
  },
  openUserMenuAction({ commit }) {
    commit("userMenuHandler", true);
  },
  setServerStatusAction({ commit }, payload) {
    commit("setServerStatus", payload);
  },
  async getCountryList({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/CountryCityDistrict/GetCountryList"
      );
      commit("setCountryList", response.data.model);
    } catch (error) {
      console.log(
        "%cBeklenmeyen Bir Hata Oluştu. Kod:" + error.response.status,
        "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px"
      );
    }
  },

  async getNotification({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get("api/notifications", {
        params:{
          pageIndex:1,
          pageSize:10,
        }
      });
      commit("setNotificationList", response);
    } catch (error) {
      console.log(
        "%cBeklenmeyen Bir Hata Oluştu. Kod:" + error.response.status,
        "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px"
      );
    }
  },


  async getCityList({ commit }, countryID) {
    try {
      const response = await Vue.prototype.$axios.get('/api/CountryCityDistrict/getCityList', {params: {
        countryId: countryID
      }})
      commit('setCityList', response.data.model)
    } catch (error) {
      console.log("%cBeklenmeyen Bir Hata Oluştu. Kod:" + error.response.status, "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px");
    }
  },
  async getDistrictList({ commit }, cityID) {
    try {
      const response = await Vue.prototype.$axios.get(
        "/api/CountryCityDistrict/getDistrictList",
        {
          params: {
            id: cityID,
          },
        }
      );
      commit("setDistrictList", response.data.model);
    } catch (error) {
      console.log(
        "%cBeklenmeyen Bir Hata Oluştu. Kod:" + error.response.status,
        "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px"
      );
    }
  },
  async getStoreListWithoutWarehouse({ dispatch, commit }) {
    try {
      const response = await Vue.prototype.$axios.get("/api/stores");
      commit("setStoreList", response.data.model);
    } catch (error) {
    }
  },
  async getStoreList({ dispatch, commit }, loading = false) {
    this.commit("app/changeAxiosLoadingVisibilty", loading);
    try {
      const response = await Vue.prototype.$axios.get("/api/stores");
      commit("setStoreList", response.data.model);
      await dispatch("getWarehouseList");
    } catch (error) {
      console.log(
        "%cBeklenmeyen Bir Hata Oluştu. Kod:" + error.response.status,
        "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px"
      );
    }
  },
  async getWarehouseList({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get("/api/warehouses", {
        params: {
          storeID: Vue.$cookies.get("storeID"),
        },
      });
      commit("setWarehouseList", response.data.model);
    } catch (error) {
      console.log(
        "%cBeklenmeyen Bir Hata Oluştu. Kod:" + error.response.status,
        "color: white; background:red; border-radius:6px; padding:3px; font-size: 13px"
      );
    } finally {
      this.commit("app/changeAxiosLoadingVisibilty", true);
    }
  },
  async getWarehouseListWithStoreName({ commit }) {
    try {
      const response = await Vue.prototype.$axios.get("/api/warehouses/types");
      commit("setWarehouseStoreList", response.data.types);
    } catch (error) {
      //HATA
    } 
  },
  //DİL KEYLERİNİ ALMA
  async getLanguageKeys({state}) {
    this.commit('app/changeAxiosLoadingVisibilty', false)
    try {
      const response = await Vue.prototype.$axios.get('/api/localizations/'+state.app.language ?? 'tr')
      localStorage.setItem('translates-'+state.app.language, JSON.stringify(response.data));
    } catch (error) {
      //HATA
    } finally {
      this.commit('app/changeAxiosLoadingVisibilty', true)
    }
  },
  //KULLANICI LİSTELERİ
  async getUserList({commit}) {
    try {
      const response = await Vue.prototype.$axios.get('/api/StoreAgent/GetSimpleAgentList')
      commit('setUserList', response.data.model)
    } catch (error) {
      //HATA
    }
  }
}
  
// mutations
const mutations = {
  setApiVersion(state, payload) {
    state.app.apiVersion = payload;
  },
  setMobileStatus(state, payload) {
    state.app.isMobile = payload;
  },
  userMenuHandler(state, payload) {
    state.userMenuDrawer = payload;
  },
  setServerStatus(state, payload) {
    state.serverStatus = payload;
  },
  setBarcode(state, payload) {
    state.barcode = payload;
  },
  setFindProductPopup(state, payload) {
    state.popups.findProduct = payload;
  },
  setFindOrderPopup(state, payload) {
    state.popups.findOrder = payload
  },
  setCountryList(state, payload) {
    state.countryList = payload;
  },
  setCityList(state, payload) {
    state.cityList = payload;
  },
  setDistrictList(state, payload) {
    state.districtList = payload;
  },
  //depo mağaza işlemleri
  setStoreList(state, payload) {
    state.storeList = payload;
  },
  setWarehouseList(state, payload) {
    state.warehouseList = payload;
  },
  setWarehouseStoreList(state, payload) {
    state.warehouseListWithStore = payload;
  },
  //axios loading
  changeAxiosLoadingVisibilty(state, payload) {
    state.axios.showLoading = payload
  },
  //müsait kullanıcını listesini basma
  setUserList(state, payload) {
    state.userList = payload
  },

  setNotificationList(state,payload){
    state.notificationList = payload.data.contents
    state.filteredNotificationList = payload.data
  },
  setFilteredNotificationList(state,payload){
    state.notificationList = payload
  },
  changeAppLanguage(state, payload) {
    localStorage.setItem('lang', payload)
    state.app.language = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
