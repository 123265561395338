<template>
  <div id="app">
    <AppLayout v-if="serverStatus">
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </AppLayout>
    <ServiceDown v-else></ServiceDown>
  </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout';
import isMobile from '@/helpers/isMobile';
import ServiceDown from '@/components/Errors/ServiceDown';
import "@flaticon/flaticon-uicons/css/all/all.css";
export default {
  name: "App",
  components: { AppLayout, ServiceDown },
  computed: {
    serverStatus() {
      return this.$store.getters['app/getServerStatus']
    }
  },
  beforeCreate() {
    if (!localStorage.getItem('translates')) {
      this.$store.dispatch('app/getLanguageKeys')
    }
    this.$store.commit('app/changeAppLanguage', localStorage.getItem('lang') || 'tr')
    this.$store.dispatch('user/setUserAction')
    this.$store.dispatch('app/setAppInformationAction', isMobile())
    // console.log(isMobile()) store'a kaydetme yapılıcak
  },
  mounted() {
    console.log("%cTicimax WMS", "color: white; background:linear-gradient(90deg, #15284b, #253f6f 74%); border-radius:6px; padding:3px 5px; font-size: 13px");
  },
}
</script>

